let valueSection = document.querySelector("section.values");
let valuesTitle = null;
let valuestext = null;
let prevSlide = 0;
let activeSlide = 1;
let duration = 4000;


if(valueSection) {
    valuesTitle = document.querySelectorAll(".value-title");
    valuesText = document.querySelectorAll(".value-text");

    valuesTitle[0].classList.add("active");
    valuesText[0].classList.add("active");


    var animation = setInterval(loopAnimation, duration);

    for (let i = 0; i < valuesTitle.length; i++) {
        valuesTitle[i].addEventListener('mouseover', function(event) {     
            clearInterval(animation);
            clearAll();
            valuesTitle[i].classList.add("active");
            valuesText[i].classList.add("active");
        });

        valuesTitle[i].addEventListener('mouseout', function(event) {    
            valuesTitle[i].classList.remove("active");
            valuesText[i].classList.remove("active");
            valuesTitle[prevSlide].classList.add("active");
            valuesText[prevSlide].classList.add("active");
            animation = setInterval(loopAnimation, duration);
        });
    }

    for (let i = 0; i < valuesText.length; i++) {
        valuesText[i].addEventListener('mouseover', function(event) {     
            clearInterval(animation);
            clearAll();
            valuesTitle[i].classList.add("active");
            valuesText[i].classList.add("active");
        });

        valuesText[i].addEventListener('mouseout', function(event) {    
            valuesTitle[i].classList.remove("active");
            valuesText[i].classList.remove("active");
            valuesTitle[prevSlide].classList.add("active");
            valuesText[prevSlide].classList.add("active"); 
            animation = setInterval(loopAnimation, duration);
        });
    }
}

function stopAnimation(animation) {
    clearInterval(animation);
}

function loopAnimation() {
    valuesTitle[activeSlide].classList.add("active");
    valuesText[activeSlide].classList.add("active");
    valuesTitle[prevSlide].classList.remove("active");
    valuesText[prevSlide].classList.remove("active");

    if(activeSlide >= valuesTitle.length-1) {
        activeSlide = 0;
        prevSlide = 2;
    } else {
        prevSlide = activeSlide;
        activeSlide++;
    }
}

function clearAll() {
    for (let i = 0; i < valuesTitle.length; i++) {
        valuesTitle[i].classList.remove("active");
        valuesText[i].classList.remove("active");
    }
}
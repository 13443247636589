import './_body-view-height';
import './header';
//import './_slideshow.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}
import './_product-slider.js';
import './_banner-block.js';
import './_block-partners.js';
import './_load-more-posts.js';
import './_product-list.js';
import './_product-slider.js';
import './_product-glide.js';
import './_case-study-glide.js';
import './_case-study-slider.js';
import './_partner-glide.js';
import './_footer.js';
import './_value-animation.js';
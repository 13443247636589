const { get } = require("jquery");


window.addEventListener("load", (event) => {
  var screenSizeBig = true;
  var screenWidth = $(window).width();

  let productList = document.querySelector(".product-list");
  let listArrow = document.querySelector(".list-arrow");
  let navListItems = document.querySelectorAll(".product-nav-list li a");
  let navList = document.querySelector(".product-nav-list");
  let technicalDetailsMobile = document.querySelectorAll(".technical-info.mobile");
  let technicalDetails = document.querySelectorAll(".technical-info.pc");
  let seeTechnical = document.querySelectorAll(".see-technical");
  let productCategory = document.querySelector(".category-container");
  let productRows = document.querySelectorAll(".product-row");
  let productNav = document.querySelectorAll(".product-nav-list li");
  
  
  screenWidth = document.body.clientWidth;
  if (screenWidth >= 900){
    screenSizeBig = true;
  } else {
    screenSizeBig = false;
  }
  
  
  if(productList) {
    let moreInfo = document.querySelectorAll(".more-info");
    let moreInfoButton = document.querySelectorAll(".show-more-info");
    let moreSubInfoButton = document.querySelectorAll(".show-more-sub-info");
    let subMoreInfo = document.querySelectorAll(".subproduct-container");
    
    let openProduct = document.querySelectorAll(".open-product");
    let openSubProduct = document.querySelectorAll(".open-sub-product");

    for (let i = 0; i < openProduct.length; i++) {
      openProduct[i].addEventListener('click', function(event) {        
        if(moreInfoButton[i].classList.contains("active")) {
          toggleVisibility(false, moreInfo[i], true);
          moreInfoButton[i].classList.remove("active");
        } else {
          toggleVisibility(true, moreInfo[i], true);
          moreInfoButton[i].classList.add("active");
          
          currentSeeTechnical = openProduct[i].parentElement.querySelector(".see-technical");
          if(currentSeeTechnical) {
            currentSeeTechnical.click();
          }
        }
      });
    }

    for (let i = 0; i < openSubProduct.length; i++) {
      openSubProduct[i].addEventListener('click', function(event) {
          if(moreSubInfoButton[i].classList.contains("active")) {
            toggleVisibility(false, subMoreInfo[i], true);
            moreSubInfoButton[i].classList.remove("active");
          } else {
            toggleVisibility(true, subMoreInfo[i], true);
            moreSubInfoButton[i].classList.add("active");
          }
      });
    }

    window.onscroll = () => {    
        var current = "";
        
        productRows.forEach((row) => {
            if (row.getBoundingClientRect().top <= 150 && row.getBoundingClientRect().top != 0) {
              current = row.getAttribute("id"); 
            }
        });

        productNav.forEach((li) => {
          li.classList.remove("active");
          if (li.classList.contains(current)) {
            li.classList.add("active");
          }
        });
    };
  }

  if(listArrow){
    let productNavMobile = document.querySelector(".product-nav");
    productNavMobile.addEventListener('click', function(event) {
      if(!screenSizeBig) {
        if(listArrow.classList.contains("active")) {
          listArrow.classList.remove("active");  
          toggleVisibility(false, navList, false);
        } else {
          listArrow.classList.add("active");
          toggleVisibility(true, navList, false);
        }
      }
    });
  }

  if(navListItems) {
    let navigation = document.getElementById("navigation");
    let productNav = document.querySelector(".product-nav");

    for (let i = 0; i < navListItems.length; i++) {
      navListItems[i].addEventListener('click', function(event) {
        if(screenSizeBig == false) {
          listArrow.classList.remove("active");  
          toggleVisibility(false, navList, false);
        }
        
        setTimeout(() => {
          productRows[i].classList.add("anchor-link-active")
        }, "100");

        setTimeout(() => {
          if(productNav) {
            navigation.classList.add("hidden");
            productNav.classList.remove("bump-down");
          }
        }, "800");

      });
    }
  }

  // Technical details tab
  if(technicalDetails.length > 0 || technicalDetailsMobile.length > 0) {
    for (let i = 0; i < seeTechnical.length; i++) {
      seeTechnical[i].addEventListener('click', function(event) {
        
        // Is technical details under a sub product?
        let subProduct = seeTechnical[i].parentElement.parentElement.parentElement.classList.contains("more-info-sub");
        let productParent = null;
        if(subProduct){
          productParent = seeTechnical[i].parentElement.parentElement.parentElement.parentElement;
        } else {
          productParent = seeTechnical[i].parentElement.parentElement.parentElement;
        }

        if(seeTechnical[i].classList.contains("active")) {
          seeTechnical[i].classList.remove("active");
          productParent.style.maxHeight = productParent.scrollHeight + "px";
          (screenSizeBig) ? toggleVisibility(false,technicalDetails[i], true) : toggleVisibility(false,technicalDetailsMobile[i], true);
        } else {
          seeTechnical[i].classList.add("active");
          if(screenSizeBig) {
            productParent.style.maxHeight = productParent.scrollHeight + technicalDetails[i].scrollHeight + 80 + "px";
            toggleVisibility(true,technicalDetails[i], true);
          } else {
            productParent.style.maxHeight = productParent.scrollHeight + technicalDetailsMobile[i].scrollHeight + 80 + "px";
            toggleVisibility(true,technicalDetailsMobile[i], true);
          } 
        }
      });
    }
    
    if(productCategory) {
      let categoryList = document.querySelector(".category-menu");
      let navListElements = document.querySelectorAll(".category-menu p");
      let categoryChooser = document.querySelector(".category-chooser");
      let activeCategoryField = document.querySelector("#active-category");
      let activeCatagory = document.querySelector(".category-menu p.active");
      //value = categoryList.value;

      categoryChooser.addEventListener("click",function(event) {
        if(categoryChooser.classList.contains("active")) {
          categoryChooser.classList.remove("active");
          toggleVisibility(false, categoryList, true)
        } else {
          categoryChooser.classList.add("active");
          categoryList.classList.add("active");
          categoryList.style.maxHeight = categoryList.scrollHeight+20+"px";
          categoryList.style.opacity = "1";
        }
      });

      for(let i=0;i< navListElements.length;i++) {
        navListElements[i].addEventListener("click",function(event) {
          activeCatagory.classList.remove("active");
          activeCatagory = navListElements[i];
          activeCatagory.classList.add("active");
          categoryChooser.classList.remove("active");
          toggleVisibility(false, categoryList, true);
          value = navListElements[i].innerText;
          activeCategoryField.innerText = value;
          for(let i = 0; i<productRows.length; i++) {
            if(productRows[i].querySelector(".product-category").innerText == value ||  value == "All") {
              productRows[i].style.display = "grid";
              navListItems[i].style.display = "flex";
            } else {
              productRows[i].style.display = "none";
              navListItems[i].style.display = "none";
            }
          }
        });
      }
    }
  }
});


function toggleVisibility(isVisible, element, toggleClass) {
  if(isVisible) {
    (toggleClass) ? element.classList.add("active"): null ;
    element.style.maxHeight = element.scrollHeight+"px";
    element.style.opacity = "1";
  } else {
    (toggleClass) ? element.classList.remove("active"): null ;
    element.style.maxHeight = "0px";
    element.style.opacity = "0";
  }
}

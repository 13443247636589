// hamburger nav

window.addEventListener('load', (event) => {
    let openButton = document.querySelector(".open-menu-button");
    let closeButton = document.querySelector(".exit-menu-button");
    let closeZone = document.querySelector(".background_trigger.menu");
    let navigation = document.getElementById("navigation");
    let nav = document.querySelector("#main-menu");
    let body = document.querySelector("body");

    openButton.onclick = function toggleMenu() {
        body.style.overflow = "hidden";
        //body.style.position = "fixed";
        nav.classList.add("show");
        setTimeout(function() {
            navigation.classList.remove("hidden");
            nav.classList.add("animate-show");
        }, 100);
    }

    closeZone.addEventListener('click', function(event) {
        nav.classList.remove("animate-show");
        body.style.overflow = "visible";
        setTimeout(function() {
            nav.classList.remove("show");
        }, 400);
    });


    closeButton.onclick = function toggleMenu() {
        body.style.overflow = "visible";
        nav.classList.remove("animate-show");
        setTimeout(function() {
            nav.classList.remove("show");
        }, 400);
    }
});

let prevScrollpos = window.pageYOffset;
window.addEventListener('scroll', function() {
    let currentScrollPos = window.pageYOffset;
    let navigation = document.getElementById("navigation");
    let productNav = document.querySelector(".product-nav");
    let productModule = this.document.querySelector(".product-list");
    
    let hero = document.querySelector(".hero");
    let bgThreshhold = 200;

    if(hero) {
        bgThreshhold = hero.getBoundingClientRect().height;
    }

    if(window.scrollY > bgThreshhold) {
        navigation.classList.add("white-bg");
    } else {
        navigation.classList.remove("white-bg");
    }

    if (prevScrollpos > currentScrollPos) {
        navigation.classList.remove("hidden");
        if(productNav && productModule.getBoundingClientRect().top < 0) {
            productNav.classList.add("bump-down");
        }
    } else {
        if(currentScrollPos > 200){
            navigation.classList.add("hidden");
            if(productNav) {
                productNav.classList.remove("bump-down");
            }
        }
    }

    if(productNav && productModule.getBoundingClientRect().top > 0) {
        productNav.classList.remove("bump-down");   
    }
    
    prevScrollpos = currentScrollPos;
});

const news =  document.querySelectorAll('.all-posts article.tease');
const moreButton = document.querySelector('.more-news');

let offset = 3; //Controls the offset on which articles to load. 3 highlight + 4 normal articles show on load.
if(news && news.length > 0 && moreButton) {
    moreButton.addEventListener("click", function(e){
        e.preventDefault();        
        let data = new FormData();
        data.append('action', 'get_news');
        data.append('offset', offset);
        $.ajax({
            type: 'POST',
            url: norwegiantech_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                var appendedNews = res;
                $('.all-posts').append(appendedNews);
                let totalPosts = $('.all-posts #posts-total').data('total');

                if (($('.all-posts .tease').length) < totalPosts) {
                    moreButton.classList.remove('hide');
                } else {
                    moreButton.classList.add('hide');
                }
            }
        });
        offset = offset+3; //Increase the offset by the numbers of articles fetch per page.
    });  
}
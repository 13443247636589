import { moveGlide } from './_case-study-glide.js';

let productSlider = document.querySelector('#case-study-slider');
let linkArrow = null;
let caseStudy = null;
let arrowOffset = null;
let arrowVisible = null;


if(productSlider) {    
    var screenSizeBig = true;
    var screenWidth = $(window).width();
    
    $(window).on("load", function() {
        caseStudy = document.querySelectorAll('.cursor-arrow-case-study-field');
        linkArrow = document.querySelector('.cursor-arrow-case-study');

        screenWidth = $(window).width();
        if ($(window).width() >= 768){
            screenSizeBig = true;
        } else {
            screenSizeBig = false;
        }
    

        
        for (let i = 0; i < caseStudy.length; i++) {
            productSlider.addEventListener('mousemove', function(event) {
                moveArrow(event, i);
            });

            caseStudy[i].addEventListener('mouseenter', function(event) {
                showArrow(i);
            });

            caseStudy[i].addEventListener('click', function(event) {
                onArrowClick(i);
            });

            caseStudy[i].addEventListener('mouseleave', function(event) {
                hideArrow(i);
            });
        }
    });

    $(window).on("resize", function() {
        screenWidth = $(window).width();
        if ($(window).width() >= 768){
            screenSizeBig = true;
        } else {
            screenSizeBig = false;
        }
    })
}


function moveArrow(event, elementNumber) {

    if (screenSizeBig) {
        let currentArrow = linkArrow;

        if (arrowOffset == null) {
            arrowOffset =  caseStudy[elementNumber].getBoundingClientRect();
        }
        let x = event.clientX;
        let y = event.clientY;
        let arrowWidth = (currentArrow.offsetWidth);
        arrowWidth = arrowWidth/2;
        
        y = y - arrowWidth;
        x = x - arrowWidth;
        linkArrow.style.left = x +'px';
        linkArrow.style.top = y +'px';
    }
    if(arrowVisible == false) {
        linkArrow.classList.remove("hover-left");
        linkArrow.classList.remove("hover");
    }
}

function showArrow(elementNumber){
    if (screenSizeBig) {
        let currentArrow = linkArrow;
        if(caseStudy[elementNumber].parentNode.parentNode.parentNode.classList.contains("full-width"))  {
            if(elementNumber+1 >= caseStudy.length) {
                currentArrow.classList.add("hover-left");    
            } else {
                currentArrow.classList.add("hover");
            }
            arrowVisible = true;
        } else if(caseStudy[elementNumber].parentNode.parentNode.classList.contains("glide__slide--active")){
            currentArrow.classList.add("hover-left");
            arrowVisible = true;
        } else {
            arrowVisible = true;
            currentArrow.classList.add("hover");
        }

    }
}

function hideArrow(elementNumber){
    if (screenSizeBig) {
        let currentArrow = linkArrow;
        if(caseStudy[elementNumber].parentNode.parentNode.parentNode.classList.contains("full-width"))  {
            if(elementNumber+1 >= caseStudy.length) {
                currentArrow.classList.remove("hover-left");    
            } else {
                currentArrow.classList.remove("hover");
            }
            arrowVisible = false;
        } else if(caseStudy[elementNumber].parentNode.parentNode.classList.contains("glide__slide--active")){
            currentArrow.classList.remove("hover-left");
            arrowVisible = false;
        } else {
            arrowVisible = false;
            currentArrow.classList.remove("hover");
        }
        arrowOffset = null;
    }
}

export function onArrowClick(elementNumber) {
    
    if(caseStudy[elementNumber].parentNode.parentNode.parentNode.classList.contains("full-width"))  {
        if(elementNumber+1 >= caseStudy.length) {
            moveGlide('<');
        } else {
            moveGlide('>');
        }
    } else if(caseStudy[elementNumber].parentNode.parentNode.classList.contains("glide__slide--active")){
        moveGlide('<');
    } else {
        moveGlide('>');
    }
}
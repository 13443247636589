let openHseq = document.querySelector("#open-hseq");
let openPrivacy = document.querySelector("#open-privacy");


if(openHseq) {
    let closeButton = document.querySelector(".exit-hseq-button");
    let closeZone = document.querySelector(".background_trigger.hseq");
    let drawer = document.querySelector(".drawer-container.hseq");
    let body = document.querySelector("body");

    openHseq.addEventListener('click', function(event) {
        drawer.classList.add("show");
        body.style.overflow = "hidden";
        setTimeout(function() {
            drawer.classList.add("animate-show");
        }, 10);
    });

    closeButton.addEventListener('click', function(event) {
        drawer.classList.remove("animate-show");
        body.style.overflow = "visible";
        setTimeout(function() {
            drawer.classList.remove("show");
        }, 400);
    });

    closeZone.addEventListener('click', function(event) {
        drawer.classList.remove("animate-show");
        body.style.overflow = "visible";
        setTimeout(function() {
            drawer.classList.remove("show");
        }, 400);
    });
}

if(openPrivacy) {
    let closeButton = document.querySelector(".exit-privacy-button");
    let closeZone = document.querySelector(".background_trigger.privacy");
    let drawer = document.querySelector(".drawer-container.privacy");
    let body = document.querySelector("body");

    openPrivacy.addEventListener('click', function(event) {
        drawer.classList.add("show");
        body.style.overflow = "hidden";
        setTimeout(function() {
            drawer.classList.add("animate-show");
        }, 10);
    });

    closeButton.addEventListener('click', function(event) {
        drawer.classList.remove("animate-show");
        body.style.overflow = "visible";
        setTimeout(function() {
            drawer.classList.remove("show");
        }, 400);
    });

    closeZone.addEventListener('click', function(event) {
        drawer.classList.remove("animate-show");
        body.style.overflow = "visible";
        setTimeout(function() {
            drawer.classList.remove("show");
        }, 400);
    });
}
import { moveGlide } from './_product-glide.js';

let productSlider = document.querySelector('#product-slider');
let openDrawer = document.querySelectorAll(".open-drawer");
let linkArrow = null;
let products = null;
let arrowOffset = null;
let arrowVisible = null;


if(productSlider) {    
    var screenSizeBig = true;
    var screenWidth = $(window).width();
    
    $(window).on("load", function() {
        products = document.querySelectorAll('.cursor-arrow-field');
        linkArrow = document.querySelector('.cursor-arrow');

        screenWidth = $(window).width();
        if ($(window).width() >= 768){
            screenSizeBig = true;
        } else {
            screenSizeBig = false;
        }
    

        
        for (let i = 0; i < products.length; i++) {
            productSlider.addEventListener('mousemove', function(event) {
                moveArrow(event, i);
            });

            products[i].addEventListener('mouseenter', function(event) {
                showArrow(i);
            });

            products[i].addEventListener('click', function(event) {
                onArrowClick(i);
            });

            products[i].addEventListener('mouseleave', function(event) {
                hideArrow(i);
            });
        }
    });

    $(window).on("resize", function() {
        screenWidth = $(window).width();
        if ($(window).width() >= 768){
            screenSizeBig = true;
        } else {
            screenSizeBig = false;
        }
    })
}


function moveArrow(event, elementNumber) {
    if (screenSizeBig) {
        let currentArrow = linkArrow;

        if (arrowOffset == null) {
            arrowOffset =  products[elementNumber].getBoundingClientRect();
        }
        let x = event.clientX;
        let y = event.clientY;
        let arrowWidth = (currentArrow.offsetWidth);
        arrowWidth = arrowWidth/2;
        
        y = y - arrowWidth;
        x = x - arrowWidth;
        linkArrow.style.left = x +'px';
        linkArrow.style.top = y +'px';
    }
    if(arrowVisible == false) {
        linkArrow.classList.remove("hover-left");
        linkArrow.classList.remove("hover");
    }
}

if(openDrawer) {
    const closeButton = document.querySelectorAll(".exit-drawer-button");
    const closeZone = document.querySelectorAll(".background_trigger.product");
    const drawer = document.querySelectorAll(".drawer-container");
    let body = document.querySelector("body");

    let drag = false;
    document.addEventListener(
        'mousedown', () => drag = false);
      
    document.addEventListener(
        'mousemove', () => drag = true);

    for (let i = 0; i < openDrawer.length; i++) {
        openDrawer[i].addEventListener('click', function(event) {
            if(!drag){
                drawer[i].classList.add("show");
                body.style.overflow = "hidden";
                setTimeout(function() {
                    drawer[i].classList.add("animate-show");
                }, 10);
            }
        });

        closeButton[i].addEventListener('click', function(event) {
            drawer[i].classList.remove("animate-show");
            body.style.overflow = "visible";
            setTimeout(function() {
                drawer[i].classList.remove("show");
            }, 400);
        });

        closeZone[i].addEventListener('click', function(event) {
            drawer[i].classList.remove("animate-show");
            body.style.overflow = "visible";
            setTimeout(function() {
                drawer[i].classList.remove("show");
            }, 400);
        });
    }
}


function showArrow(elementNumber){
    if (screenSizeBig) {
        let currentArrow = linkArrow;
        
        if(products[elementNumber].parentNode.parentNode.classList.contains("glide__slide--active")){
            currentArrow.classList.add("hover-left");
            arrowVisible = true;
        } else {
            arrowVisible = true;
            currentArrow.classList.add("hover");
        }

    }
}

function hideArrow(elementNumber){
    if (screenSizeBig) {
        let currentArrow = linkArrow;
        if(products[elementNumber].parentNode.parentNode.classList.contains("glide__slide--active")){
            currentArrow.classList.remove("hover-left");
            arrowVisible = false;
        } else {
            arrowVisible = false;
            currentArrow.classList.remove("hover");
        }
        arrowOffset = null;
    }
}

export function onArrowClick(elementNumber) {
    if(products[elementNumber].parentNode.parentNode.classList.contains("glide__slide--active")){
        moveGlide('<');
    } else {
        moveGlide('>');
    }
}
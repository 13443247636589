import Glide from "@glidejs/glide";
import { escapeSelector } from "jquery";
let glide = null;

function initpartnerSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        gap: 20,
        perView: 6,
        autoplay: 2000,
        animationDuration: 1000,
        animationTimingFunc: 'ease',
        bound: 4,
        breakpoints: {
            900: {
                perView: 3,
                gap: 20,
            },
            1440: {
                perView: 5,
                gap: 40,
            }
        }
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initpartnerSlider = initpartnerSlider;